
<template>
  <div class="allmsg">
    <van-nav-bar
      v-if="sharetype == true"
      title="邀请有礼"
      left-arrow
      @click-left="onClickLeft"
    >
      <!-- <template #right>
        <img @click="clicksharemeth2" class="fen" :src="fenxiang" alt="" />
      </template> -->
    </van-nav-bar>
    <!-- <div class="a1"></div>
    <div class="qrshareit">
      <div class="canclass">
        <qrcode
          id="qeides"
          :url="url"
          :iconurl="icon"
          :wid="88"
          :hei="88"
          :imgwid="20"
          :imghei="16"
        ></qrcode>
      </div>
      <div class="theqrmsg">
        <div>我是{{ username }}</div>
        <div>我在使用萝卜猎手 企业用人更放心</div>
        <div>长按识别二维码 领取免费体验包</div>
      </div>
    </div>
    <div @click="$router.push('/invitemsg')" v-if="sharetype==true" class="yqclass">邀请指南</div>
    
    <buttones v-if="sharetype==true" :msg="buttonmsg"></buttones>
    <sharepage ref="sharitid"></sharepage>
    <sharees ref="shareid"></sharees> -->
    <div class="qrclass">
      <qrcode
        id="qeides"
        :url="url"
        :iconurl="icon"
        :wid="88"
        :hei="88"
        :imgwid="20"
        :imghei="16"
      ></qrcode>
      <canvas id="myCanvas"></canvas>
    </div>
    <div v-show="!divsrc" class="">
      <img style="width: 100%" src="../../assets/fx3.png" alt="" />
    </div>
    <div class="divclass"><img :src="divsrc" class="divsrcclass" alt="" /></div>
    <div class="fexmsgclass">
      <div><span>*</span> 长按海报保存至相册,转发邀请好友领取免费体验</div>
    </div>
    <div
      @click="$router.push('/invitemsg')"
      v-if="sharetype == true"
      class="yqclass"
    >
      邀请指南
    </div>
    <buttones v-if="sharetype == true" :msg="buttonmsg"></buttones>
    <div class="nomsgclass"></div>
    <sharepage ref="sharitid"></sharepage>
    <sharees ref="shareid"></sharees>
  </div>
</template>
<script>
import fenxiang from "../../assets/fenxiang.png";
import qrcode from "vue_qrcodes";
import buttones from "../../components/botton/index";
import sharepage from "../../views/sharepage/index";
import sharees from "../../components/share/index";
import base from "../../../util/base";
export default {
  components: {
    qrcode,
    buttones,
    sharepage,
    sharees,
  },
  data() {
    return {
      url:
        base.tologinB +
        "/register?invitecode=" +
        sessionStorage.getItem("phone"),
      icon: require("../../assets/users3.png"),
      buttonmsg: "生成我的官网，去邀请",
      fenxiang: fenxiang,
      username: sessionStorage.getItem("username"),
      sharetype: true,
      canvasmsg: "",
      can: "",
      divsrc: "",
    };
  },
  mounted() {
    this.clicksharemeth();
    this.can = document.getElementById("myCanvas");
    var img = new Image();
    this.can.width = document.documentElement.clientWidth * 2;
    this.can.height = document.documentElement.clientWidth * 2 * 1.5;
    this.canvasmsg = this.can.getContext("2d");
    img.src = require("../../assets/fx3.png");
    img.onload = () => {
      this.canvasmsg.drawImage(
        img,
        0,
        0,
        document.documentElement.clientWidth * 2,
        document.documentElement.clientWidth * 2 * 1.5
      );
      setTimeout(() => {
        this.drawimgtwometh();
      });
    };
    this.can.style =
      "position: absolute;top:-50rem;transform-origin: 0px 0px;zoom:0.5;";
    // if (this.$route.query.isshare == "1") {
    //   this.sharetype = false;
    //   this.url =
    //     "https://copm.luobolieshou.com/register?invitecode=" +
    //     this.$route.query.msg;
    //   this.username = this.$route.query.username;
    // } else {
    //   this.clicksharemeth();
    // }
  },
  methods: {
    // 画第二个图片
    drawimgtwometh() {
      let qrimg = document.getElementsByClassName("qrcode")[0].childNodes[2].childNodes[0].toDataURL("image/png");
      let img2 = new Image();
      img2.src = qrimg;
      img2.onload = () => {
        this.canvasmsg.drawImage(
          img2,
          ((document.documentElement.clientWidth * 2) / 100) * 39.5,
          ((document.documentElement.clientWidth * 2) / 100) * 110.9,
          ((document.documentElement.clientWidth * 2) / 100) * 20,
          ((document.documentElement.clientWidth * 2) / 100) * 20
        );
        let imgurl = this.can.toDataURL("image/png");
        this.divsrc = imgurl;
        // let imgdom = document.createElement("img");
        // imgdom.src = imgurl;
        // imgdom.onload = () => {
        //   imgdom.style = "width:100%";
        //   document.getElementsByClassName("divclass")[0].appendChild(imgdom);
        // };
      };
    },
    /**
     * 点击分享按钮
     */
    toshare() {
      this.$refs.shareid.clickshare();
    },
    /**
     * 点击返回键
     */
    onClickLeft() {
      this.$router.go(-1);
    },
    clicksharemeth2() {
      this.toshare();
    },
    clicksharemeth() {
      let obj = JSON.stringify({
        sharetitle: "发现一款用人神器",
        sharedesc: "用工风险降低95%，招聘准确率上升80%，即刻免费体验！",
        isshare: "1",
        shareurl: "shareit",
        tourlmsg:
          base.toguan + "%2F%23%2F?invitecode=" + sessionStorage.getItem("phone"),
        msg: sessionStorage.getItem("phone"),
        username: sessionStorage.getItem("username"),
      });
      localStorage.setItem("shareobj", obj);
    },
    /**
     * 点击去邀请
     */
    clickmeth() {
      this.toshare();
      this.clicksharemeth();
    },
  },
};
</script>
<style scoped>
.divsrcclass {
  width: 100%;
}
.nomsgclass {
  height: 0.5rem;
}
.fexmsgclass {
  padding: 0.2rem;
  line-height: 0.4rem;
}
.fexmsgclass > span {
  color: #f55613;
}
.fexmsgclass > div {
  text-align: center;
  font-size: 0.3rem;
  color: #5e5c5c;
}
.qrclass {
  position: absolute;
  top: -50rem;
}
.fx_2class {
  width: 100%;
}
.theqrmsg {
  color: #1f1e1e;
  font-size: 0.25rem;
  position: absolute;
  bottom: 1.2rem;
  left: 0.3rem;
}
.fen {
  width: 0.4rem;
  height: 0.41rem;
}
#qeides {
  padding: 0.1rem;
  background-color: #ffffff;
}
.yqclass {
  color: #f55613;
  font-size: 0.36rem;
  margin-bottom: 0.24rem;
  margin-top: 0.3rem;
  margin-bottom: 0.6rem;
  text-align: center;
}
.a1 {
  height: 1px;
}
.allmsg {
  /* padding-bottom: 1.5rem; */
  background-color: #ffffff;
  width: 100%;
  overflow: hidden;
}
.qrshareit {
  margin: 0.44rem 0.34rem;
  margin-bottom: 0;
  height: 9.97rem;
  background-image: url("../../assets/shareitimg.png");
  background-size: 100% 100%;
  position: relative;
}
.canclass {
  position: absolute;
  bottom: 0.94rem;
  right: 0.58rem;
}
.qrmsg {
  position: absolute;
  font-size: 0.2rem;
  line-height: 0.28rem;
  right: 0.58rem;
  bottom: 0.51rem;
  color: #ffffff;
}
.qrp {
  margin-top: 0.76rem;
  font-size: 0.34rem;
  color: #333333;
}
.qrp p {
  text-align: center;
}
</style>